<template lang="pug">
	.d-flex.align-items-center.basic-box-shadow.border-rounded.w-100.foreground-color.p-3.p-sm-4.created-bot
		img.mr-3.connected-bot__logo.created-bot__avatar(v-if="image" :src="image" :alt="`${$t('project.logo')} ${name}`" width="65" height="65")
		.mr-3.d-flex.justify-content-center.align-items-center.flex-shrink-0.connected-bot__logo.created-bot__avatar(v-else="image")
			span 🤖
		.d-flex.align-items-center.flex-wrap
			h2.h3.text-bold.created-bot__title {{ name }}
			span.b1.text-grey.created-bot__login {{ login }}
			pw-icon-copy(:text="`${login}`", :textSuccess="`😊 &nbsp;&nbsp;&nbsp;${this.$t('resources.copyResources')}`")
</template>

<script>
import PwIconCopy from "@/components/Form/PwIconCopy";

export default {
	name: 'CreatedBot',
	components: {
		PwIconCopy,
	},
	props: {
		name: {
			default : '',
			type : String
		},
		image: {
			default : '',
			type : String
		},
		login: {
			default : '',
			type : String
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.created-bot {
	@include respond-below(xs){
		padding-left: 17px !important;
	}

	&__avatar {
		width: 89px;
		height: 89px;

		@media (min-width: 576px){
			width: 65px;
			height: 65px;
			font-size: 32px;
		}
	}

	&__title {
		margin-bottom: 10px !important;
		width: 100%;

		@media (min-width: 576px){
			margin-bottom: 0 !important;
			width: auto;
		}
	}

	&__login {
		margin-left: 0;
		margin-right: 10px;

		@media (min-width: 576px){
			margin-left: 10px;
		}
	}

	.fontello-icon-copy {
		font-size: 24px;
	}
}

</style>
