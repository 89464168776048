<template lang='pug'>
	.permissions-bot
		.d-flex.justify-content-between.flex-wrap.permissions-bot__wrap(v-if="resource == 'channel'")
			.b4.border-rounded.mb-10.permissions-bot__inner.d-none.d-sm-block.permissions-bot__col
				span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.channel.messages.title') }}
				ul.p-0.list-unstyled.mb-0.permissions-bot__list
					li.position-relative.permissions-bot__checkbox(v-for='item in channelMessages' :key='item' :class="{'is-dark': isDarkColor}") {{ $t(item) }}
			.d-flex.flex-wrap.permissions-bot__col
				.b4.border-rounded.mb-10.w-100.order-2.permissions-bot__inner
					span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.channel.stories.title') }}
					ul.p-0.list-unstyled.mb-0.permissions-bot__list
						li.position-relative.permissions-bot__checkbox(v-for='item in channelStories' :key='item' :class="{'is-dark': isDarkColor}") {{ $t(item) }}
				.b4.border-rounded.mb-10.order-1.d-block.d-sm-none.permissions-bot__inner.permissions-bot__col
					span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.channel.messages.title') }}
					ul.p-0.list-unstyled.mb-0.permissions-bot__list
						li.position-relative.permissions-bot__checkbox(v-for='item in channelMessages' :key='item' :class="{'is-dark': isDarkColor}") {{ $t(item) }}
				.b4.border-rounded.mb-10.w-100.order-0.permissions-bot__inner
					span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.channel.addingSubscribers') }}

		.d-flex.justify-content-between.flex-wrap.permissions-bot__wrap(v-if="resource == 'group'")
			.b4.border-rounded.mb-10.permissions-bot__inner.permissions-bot__col
				span.position-relative.d-block.mb-4.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.group.deletingMessages') }}
				span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.group.blockingUsers') }}
			.b4.border-rounded.mb-10.permissions-bot__inner.permissions-bot__col
				span.position-relative.d-block.mb-4.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.group.inviteLinks') }}
				span.position-relative.d-block.permissions-bot__switch(:class="{'is-dark': isDarkColor}") {{ $t('instructionBot.permissionsBot.group.pinningMessages') }}
</template>

<script>
export default {
	name: 'PermissionsBot',
	props: {
		resource: {
			default : '',
			type : String
		}
	},
	data() {
		return {
			channelMessages: ['instructionBot.permissionsBot.channel.messages.publishing', 'instructionBot.permissionsBot.channel.messages.editing', 'instructionBot.permissionsBot.channel.messages.deleting'],
			channelStories: ['instructionBot.permissionsBot.channel.stories.publishing']
		};
	}
}

</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

	.permissions-bot {
		&.is-success {
			display: none;
		}

		&__col {
			width: 100%;

			@media (min-width: 576px){
				width: calc(50% - 5px);
			}
		}

		&__inner {
			background-color: var(--primary-bg-color);
			padding: 21px 20px 21px 18px;

			&.order-0 {
				order: 0;
			}

			&.order-1 {
				order: 1;
			}

			&.order-2 {
				order: 2;
			}

			@media (min-width: 576px){
				order: 0 !important;
			}
		}

		&__switch {
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				right: 0;
				width: 33px;
				height: 20px;
				background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='33' height='20' viewBox='0 0 33 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.7749 20L10.2251 20C4.57792 20 3.73895e-07 15.5228 8.35117e-07 10C1.29634e-06 4.47715 4.57792 4.23365e-06 10.2251 4.75047e-06L22.7749 5.89899e-06C28.4221 6.4158e-06 33 4.47716 33 10C33 15.5229 28.4221 20 22.7749 20Z' fill='%2352A7F9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.4249 1.65991C27.9337 1.65991 31.5885 5.26648 31.5885 9.71516C31.5885 14.1638 27.9337 17.7704 23.4249 17.7704C18.917 17.7704 15.2622 14.1638 15.2622 9.71516C15.2622 5.26648 18.917 1.65991 23.4249 1.65991Z' fill='white'/%3E%3C/svg%3E%0A");
			}

			&.is-dark {
				&:after {
					background-image: url("data:image/svg+xml,%3Csvg width='33' height='20' viewBox='0 0 33 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.7749 20L10.2251 20C4.57792 20 3.73895e-07 15.5228 8.35117e-07 10C1.29634e-06 4.47715 4.57792 4.23365e-06 10.2251 4.75047e-06L22.7749 5.89899e-06C28.4221 6.4158e-06 33 4.47716 33 10C33 15.5229 28.4221 20 22.7749 20Z' fill='%2352A7F9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.4249 1.65991C27.9337 1.65991 31.5885 5.26648 31.5885 9.71516C31.5885 14.1638 27.9337 17.7704 23.4249 17.7704C18.917 17.7704 15.2622 14.1638 15.2622 9.71516C15.2622 5.26648 18.917 1.65991 23.4249 1.65991Z' fill='%2327282A'/%3E%3C/svg%3E%0A");
				}
			}
		}

		&__list {
			margin-top: 23px;
		}

		&__checkbox {
			padding-left: 31px;
			margin-bottom: 23px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 0;
				width: 21px;
				height: 22px;
				background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='21' rx='10.5' fill='%2352A7F9'/%3E%3Cpath d='M14.4375 7.875L9.02344 13.7812L6.5625 11.0966' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}

			&:last-of-type {
				margin-bottom: 0px !important;
			}

			&.is-dark {
				&:after {
					background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='21' rx='10.5' fill='%2352A7F9'/%3E%3Cpath d='M14.4375 7.875L9.02344 13.7812L6.5625 11.0966' stroke='%2327282A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				}
			}
		}
	}
</style>
