<template lang='pug'>
	.d-flex.create.create-bot.position-relative.flex-column.align-items-center
		.create-bot__w-100.d-flex.justify-content-end
			.create-bot__top-right-buttons.align-items-center
				locale-switch-simple
				span.create-bot__closing(:style="{backgroundColor: isDarkColor ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}" @click='goBack()')
					svg(xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none")
						path(d="M4.36542 4.36572L12.1036 12.1039" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
						path(d="M4.36542 12.104L12.1036 4.36579" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")

		.create-bot__content.position-relative(:class="{'create-bot__content--half-width': step == 8}")
			.cursor-pointer.create__btn-back.create-bot__btn-back(:class="{'is-clear': step == 0}" @click="handleClickBack")
				svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
					path(d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
					path(d="M20.4999 12H3.66992" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")

			.create-bot__top-right-buttons.align-items-center.create-bot__top-right-buttons--inner
				locale-switch-simple
				span.create-bot__closing(:style="{backgroundColor: isDarkColor ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}" @click='goBack()')
					svg(xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none")
						path(d="M4.36542 4.36572L12.1036 12.1039" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
						path(d="M4.36542 12.104L12.1036 4.36579" :stroke="isDarkColor ? '#fff' : '#082846'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")

			.create-bot__wrap.d-flex.justify-content-between.flex-column.flex-lg-row(:class="{'align-items-start': step == 6}")
				.create-bot__left(v-if="step == 0" style="background: url('/assets/img/createBot/zero_step.webp') center bottom / contain no-repeat, var(--primary-bg-color);")
				.create-bot__left(v-else-if="step == 1" :style="{background: `url('/assets/img/createBot/first_step_${isDarkColor ? 'dark' : 'light'}.webp') top 35px center / 74% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 2" :style="{background: `url('/assets/img/createBot/second_step_${isDarkColor ? 'dark' : 'light'}.webp') top center / 91.5% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 3" :style="{background: `url('/assets/img/createBot/third_step_${isDarkColor ? 'dark' : 'light'}.webp') top right / 94.4% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 4" :style="{background: `url('/assets/img/createBot/fourth_step_${isDarkColor ? 'dark' : 'light'}.webp') top right / 94.4% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 5" :style="{background: `url('/assets/img/createBot/fifth_step_${isDarkColor ? 'dark' : 'light'}.webp') top right / 94.4% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 6" :style="{background: `url('/assets/img/createBot/sixth_step_${isDarkColor ? 'dark' : 'light'}.webp') top right / 94.4% no-repeat, var(--primary-bg-color)`}")
				.create-bot__left(v-else-if="step == 7" :style="{background: `url('/assets/img/createBot/seventh_step_${isDarkColor ? 'dark' : 'light'}.webp') center / 56.5% no-repeat, var(--primary-bg-color)`}")

				.d-flex.flex-column.create-bot__right(v-if="step <= 7")
					.d-flex.flex-column.mx-auto.h-100.mt-0.create__wrap
						.position-relative.create-bot__step-wrap.in-zoom(:class="{'d-none': (step == 0 || step == 7), 'd-flex': (step != 0 && step != 7)}")
							svg(class="create__wrap-line" width='21' height='3' viewbox='0 0 21 3' fill='none' xmlns='http://www.w3.org/2000/svg')
								line(x1='0.5' y1='1.5' x2='20.5' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='5 10')
							.position-relative.create__step-inner.create-bot__step-inner.d-flex.justify-content-end(v-for="i in 6")
								.create-bot__step-line.d-flex.justify-content-center.align-items-center
									svg.d-block.d-sm-none(width='10' height='3' viewbox='0 0 10 3' fill='none' xmlns='http://www.w3.org/2000/svg')
										line(y1='1.5' x2='10' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='2.5 5')
									svg.d-none.d-sm-block(width='21' height='3' viewbox='0 0 21 3' fill='none' xmlns='http://www.w3.org/2000/svg')
										line(x1='0.5' y1='1.5' x2='20.5' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='5 10')
								.create__step.create-bot__step(:class="{'active pulsing': step == i, 'done': step > i}")
									span(v-if="step <= i") {{ i }}
									img(v-if="step > i" src="/assets/img/icons/done-green.svg")

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 0")
							div
								h3.mb-4 {{ $t('instructionBot.step0.title') }}
								.create-bot__left.create-bot__left--zero.d-block.d-lg-none(style="background: url('/assets/img/createBot/zero_step.webp') left 7px bottom -13px / 104% no-repeat, var(--primary-bg-color);")
								p.b3.mb-4.text-grey.mt-4.mt-lg-0(v-html="$t('instructionBot.step0.desc')")
							.create-bot__inner
								span.create-bot__inner-txt {{ $t('instructionBot.step0.txt') }}
								.btn.btn-primary.create-bot__btn(@click="step += 7, isAPIToken = true, reset()") {{ $t('instructionBot.step0.button1') }}
								.btn.create-bot__btn.create-bot__btn-help(@click="step++, isAPIToken = false") {{ $t('instructionBot.step0.button2') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 1")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold 🤖 {{ $t('instructionBot.step1.title') }}
								p.b1.mb-4.text-grey
									span(v-html="`${$t('instructionBot.step1.desc')}`")
									span
										| &#x20;&#x20;

									span.text-link.text-bold.text-nowrap
										span.mr-1 @BotFather
										pw-icon-copy.ml-auto(:text="'@BotFather'", :textSuccess="`😊 &nbsp;&nbsp;&nbsp;${this.$t('resources.copyResources')}`") &nbsp;
								.create-bot__note.d-flex.mb-4.mb-lg-0
									svg.flex-shrink-0(xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewbox='0 0 25 24' fill='none')
										path(d='M15.5 22C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M18.8811 7.58023C18.8811 6.81023 18.2611 6.25024 17.5011 6.31024H17.4611C16.1211 6.42024 14.0911 7.11025 12.9511 7.82025L12.8411 7.89026C12.6611 8.00026 12.3511 8.00026 12.1611 7.89026L12.0011 7.79025C10.8711 7.08025 8.8411 6.41023 7.5011 6.30023C6.7411 6.24023 6.12109 6.81025 6.12109 7.57025V15.2702C6.12109 15.8802 6.62108 16.4602 7.23108 16.5302L7.41107 16.5602C8.79107 16.7402 10.9311 17.4502 12.1511 18.1202L12.1811 18.1302C12.3511 18.2302 12.6311 18.2302 12.7911 18.1302C14.0111 17.4502 16.1611 16.7502 17.5511 16.5602L17.7611 16.5302C18.3811 16.4602 18.8811 15.8902 18.8811 15.2702V7.58023Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M12.5 8.10059V17.6606' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
									span.create-bot__note-inner(v-html="`${$t('instructionBot.step1.note')}`")
								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/first_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.btn.d-none.create-bot__btn(@click="step--") {{ $t('wizard.prev') }}
							.btn.btn-primary.create-bot__btn(@click="step++") {{ $t('wizard.next') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 2")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold {{ $t('instructionBot.step2.title') }}
								p.b1.mb-4.text-grey(v-html="$t('instructionBot.step2.desc')")
								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/second_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="step++") {{ $t('wizard.next') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 3")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold 💬 {{ $t('instructionBot.step3.title') }}
								p.b1.mb-4.text-grey
									span(v-html="`${$t('instructionBot.step3.desc1')}`")
									span.font-weight-medium.create-bot__copy /newbot
									pw-icon-copy.ml-auto(:text="'/newbot'", :textSuccess="`${$t('success.dataCopied')}`")
									span(v-html="`&nbsp;&nbsp;${$t('instructionBot.step3.desc2')}`")

								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/third_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="step++") {{ $t('wizard.next') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 4")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold 💬 {{ $t('instructionBot.step4.title') }}
								p.b1.mb-4.text-grey(v-html="$t('instructionBot.step4.desc')")

								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/fourth_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="step++") {{ $t('wizard.next') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 5")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold 💬 {{ $t('instructionBot.step5.title') }}
								p.b1.mb-4.text-grey(v-html="$t('instructionBot.step5.desc')")

								.create-bot__note.create-bot__info.d-flex.mb-4.mb-lg-0
									svg.flex-shrink-0(width='25' height='24' viewbox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg')
										rect(x='2.5' y='2' width='20' height='20' rx='5' stroke='#FCB64D' stroke-width='1.5')
										path(d='M13 17L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M11 11L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M13 8L13 7' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
									span.create-bot__note-inner(v-html="`${$t('instructionBot.step5.info')}`")

								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/fifth_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="step++, reset()") {{ $t('wizard.next') }}

						.h-100.d-flex.flex-column.justify-content-between(v-if="step == 6")
							.mb-4.mb-lg-0
								h3.mb-4.text-bold 🎉 {{ $t('instructionBot.step6.title') }}
								p.b1.mb-4.text-grey(v-html="$t('instructionBot.step6.desc')")
								my-input.mb-4.mb-lg-0(
									v-model="value"
									:label="$t('instructionBot.form.name')"
									:errorFeedback="error"
									@blur='validationAPIToken'
									@input='validationAPIToken'
								)

								.border-rounded.d-flex.align-items-center.mb-4.mt-4.mb-lg-0.create-bot__info.create-bot__one-system
									svg.flex-shrink-0(width='25' height='24' viewbox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg')
										rect(x='2.5' y='2' width='20' height='20' rx='5' stroke='#FCB64D' stroke-width='1.5')
										path(d='M13 17L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M11 11L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M13 8L13 7' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
									span.b3.create-bot__note-inner(v-html="`${$t('instructionBot.step6.info')}`")

								img.w-100.d-block.d-lg-none(:src="`/assets/img/createBot/sixth_step_${isDarkColor ? 'dark' : 'light'}_mobile.webp`" alt="Screenshot from Telegram")
							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}

								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="item.isNeuro() ? addBot() : step += 2, isAPIToken = false" :class="{'disabled': isDisabled || isloading}")
									.loader(v-if="isloading")
									span(v-else) {{ $t('forms.send') }}

						.d-flex.flex-column.justify-content-between.h-100(v-if="step == 7")
							.mb-4.mb-lg-0
								.mb-20.create-bot__left.d-block.d-lg-none(:style="{background: `url('/assets/img/createBot/seventh_step_${isDarkColor ? 'dark' : 'light'}.webp') center / 60% no-repeat, var(--primary-bg-color)`}")
								h3.create-bot__title {{ $t('instructionBot.form.name') }}
								p.b1.mb-4.text-grey.create-bot__txt(v-html="$t('instructionBot.stepAPI.desc')")
								my-input.create-bot__input.create-bot__input-token(
									v-model="value"
									:label="$t('instructionBot.form.name')"
									:errorFeedback="error"
									@blur='validationAPIToken'
									@input='validationAPIToken'
									:maxlength="46"
								)

								.border-rounded.d-flex.align-items-center.mt-4.create-bot__info.create-bot__one-system.is-step-7
									svg.flex-shrink-0(width='25' height='24' viewbox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg')
										rect(x='2.5' y='2' width='20' height='20' rx='5' stroke='#FCB64D' stroke-width='1.5')
										path(d='M13 17L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M11 11L13 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
										path(d='M13 8L13 7' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
									span.b3.create-bot__note-inner(v-html="`${$t('instructionBot.step6.info')}`")

							.d-flex.justify-content-between
								.btn.create-bot__btn.create__btn--half-width(@click="step = 0") {{ $t('wizard.prev') }}

								.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="addBot" :class="{'disabled': isDisabled || isloading}")
									.loader(v-if="isloading")
									span(v-else) {{ $t('forms.send') }}

				.h-100.d-flex.flex-column.justify-content-between(v-if="step == 8")
					add-bot-to-resources

					.d-flex.justify-content-between
						.btn.create-bot__btn.create__btn--half-width(@click="handleClickBack") {{ $t('wizard.prev') }}
						.btn.btn-primary.create-bot__btn.create__btn--half-width(@click="checkBotPermissions") {{ $t('forms.check') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyInput from "@/components/UI/MyInput";
import IconClose from "@/components/Common/IconClose";
import PwIconCopy from "@/components/Form/PwIconCopy";
import LocaleSwitchSimple from "@/components/Common/LocaleSwitchSimple";
import CreateLeftBasic from "@/components/Common/CreateLeftBasic";
import AddBotToResources from "@/components/Tables/AddBotToResources";
import { STORAGE } from '@/api/storage';

export default {
	name: 'Create',
	components: {
		LocaleSwitchSimple,
		MyInput,
		IconClose,
		PwIconCopy,
		AddBotToResources,
		CreateLeftBasic
	},
	metaInfo() { return { title: this.$t('project.addBot') } },
	data: () => ({
		value: null,
		isloading: false,
		step: 8,
		isAPIToken: false,
		isDisabled: false,
		error: ''
	}),
	computed: {
		...mapGetters({
			item: 'project/opened',
			isPending: 'project/isPending',
		})
	},
	mounted() {
		this.step = this.$route.params.step || 0;

		this.$root.$on('bv::modal::hide', () => {
			this.value = null;
			this.isloading = false;
			this.error = '';
		});

		this.validationAPIToken();
	},
	methods: {
		...mapActions({
			add: 'bot/add'
		}),
		handleClickBack() {
			if (this.step == 0) {
				this.goBack();
			} else if (this.isAPIToken && this.step == 7) {
				this.step = 0;
			} else if (this.isAPIToken || this.step != 8) {
				this.step--;
				this.isloading = false;
			} else {
				this.step -= 2;
				this.isloading = false;
			}
		},
		async addBot() {
			this.isloading = true;

			this.add({type: {value: 'NEURO'}, project: this.item.id, token: this.value }).then(i => {
				STORAGE.setBot(true);
				this.goBack();
			})
				.catch(v => {
					if (v == "Unauthorized")
						this.error = this.$t('errors.APItokenNotValid');

					if (v != "Unauthorized")
						this.error = v;

					this.isloading = false;
				})
		},
		validationAPIToken() {
			const regex = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/i;

			this.isDisabled = !(this.value != null && this.value != '');
			this.error = this.value == '' ? this.$t('errors.enterAPItoken') : '';

			if (this.value && this.value.length > 0) {
				if (!regex.test(this.value)) {
					this.isDisabled = true
					this.error = this.$t('errors.alpha.noValidToken');
				} else {
					this.error = '';
					this.isDisabled = false;
				}
			}
		},
		checkBotPermissions() {
			/**
			 * Этот код срабатывает при нажатии на кнопку "Проверить" в блоке "Добавьте нового бота в ресурсы"
			 * Написала код этой функции для тестирования верстки (не является рабочим)
			 */
			let resourcesBotPermissionsElems = document.querySelectorAll('.resources-bot-permissions');
			let permissionsBotElems = document.querySelectorAll('.permissions-bot');

			let isPermissions = [true, false, false, true, true, true];

			for (let i = 0; i < resourcesBotPermissionsElems.length; i++) {
				if (isPermissions[i]) {
					resourcesBotPermissionsElems[i].classList.add('is-success');
					permissionsBotElems[i].classList.add('is-success');
				} else {
					resourcesBotPermissionsElems[i].classList.add('is-error');
					permissionsBotElems[i].classList.add('is-error');
				}
			}
		},
		goBack() {
			return this.$router.push({ name: 'project_bot', params: { id: this.item.id } });
		},
		reset() {
			this.error = '';
			this.value = null;
			this.isDisabled = true;
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.create-bot {
	background-color: var(--foreground-color);

	@media (min-width: 992px) {
		justify-content: center;
	}

	&__top-right-buttons {
		top: -1px;
		right: 17px;
		display: none;

		@media (min-width: 992px) {
			right: 40px;
			display: flex;
		}

		&--inner {
			display: flex;
			position: absolute;

			@media (min-width: 992px) {
				display: none;
			}
		}
	}

	&__content {
		max-width: 497px;
		width: 100%;
		padding: 0 17px;
		margin-bottom: 65px;
		margin-top: 28px;
		z-index: 11;

		@media (min-width: 992px) {
			max-width: 1111px;
			margin-top: 85px;
			margin-bottom: 147px;
		}

		&--half-width {
			max-width: 602px;
			margin-bottom: 41px;
			margin-top: 40px;

			@include respond-below(xs) {
				margin-bottom: 33px;
			}
		}

		&-new {
			margin-bottom: 120px;

			@media (min-width: 992px) {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		margin-bottom: 30px !important;

		@media (min-width: 992px) {
			margin-bottom: 20px !important;
		}
	}

	&__txt {
		margin-bottom: 24px !important;
		margin-top: 30px;

		@media (min-width: 992px) {
			margin-bottom: 30px !important;
			margin-top: 20px;
		}
	}

	&__left {
		width: 100%;
		background-color: var(--primary-bg-color);
		border-radius: 8px;
		border: 1px solid var(--grayscale-dark-line);
		min-height: 225px;
		display: none;

		@media (min-width: 992px) {
			width: 51.44%;
			min-height: 368px;
			display: block;
		}

		&--zero {
			@media (min-width: 400px) {
				background-size: contain !important;
				background-position: center !important;
			}
		}
	}

	&__right {
		width: 100%;

		@media (min-width: 992px) {
			width: 42.99%;
		}
	}

	&__wrap {
		width: 100%;
	}

	&__btn {
		&-help {
			transition: none;
			padding-left: 54px;
			padding-right: 54px;

			@media (min-width: 992px) {
				padding: 13px;
			}
		}
	}

	&__step {
		&-wrap {
			margin-top: 3px;
			margin-bottom: 21px;

			@media (min-width: 992px) {
				margin-top: 0;
				margin-bottom: 32px;
			}
		}

		&-inner {
			width: calc((100% - 35px * 6));

			@media (min-width: 576px) {
				width: calc((100% - 45px * 6));
			}

			&:nth-child(2) {
				width: 35px;

				@media (min-width: 576px) {
					width: 45px;
				}
			}
		}

		&-line {
			width: calc(100% - 35px);
			height: 100%;

			@media (min-width: 576px) {
				width: calc(100% - 45px);
			}
		}
	}

	&__note {
		background-color: var(--light-primary);
		padding: 16px 15px;
		font-size: 14px;
		line-height: 160%;
		border-radius: 8px;

		@media (min-width: 576px) {
			padding: 19px 20px;
			font-size: 16px;
			line-height: 150%;
		}

		&-inner {
			margin-left: 11px;
			margin-top: -1px;
			margin-bottom: 0;

		}
	}

	&__copy {
		color: var(--main-text-color);
		background-color: var(--bg-back);
		border: 1px solid var(--light-gray);
		padding: 2px 7px;
		margin: 0 7px;
		font-family: "Fira Code";
	}

	&__info {
		background-color: var(--brand-bg-warning);
	}

	&__one-system {
		color: var(--brand-orange);
		padding: 10px;
	}

	&__input {
		margin-bottom: 3px;

		@media (min-width: 992px) {
			margin-top: 29px;
			margin-bottom: 44px;
		}

		&-token {
			margin-bottom: 24px;

			@media (min-width: 992px) {
				margin-bottom: 30px;
			}
		}
	}

	.my-input {
		&.is-error {
			.my-input__reset {
				display: none;

				&-error {
					display: block;
					z-index: 1;
					position: absolute;
					top: 16px;
					right: 19px;

					path {
						stroke: #fff;

						&:first-child {
							stroke: var(--brand-error);
							fill: var(--brand-error);
						}
					}
				}
			}
		}
	}
}
</style>
